/**
 * @author    Tomas Hyl (tomas.hyl@stratox.cz)
 */

import type { IEnvironment } from './environments/interface/environment.interface';

export function fetchConfigAndSetWindowEnvironment(): void {
  fetch(`assets/config/config.json?t=${Date.now()}`)
    .then(async response => {
      (window as unknown as { appEnvironmentConfig: IEnvironment; }).appEnvironmentConfig = await response.json() as IEnvironment;

      return Promise.all([
        import('@angular/platform-browser-dynamic'),
        import('./app/AppModule/app.module'),
      ])
        .then(([platformBrowserDynamicImport, appModuleImport]) => {
          return platformBrowserDynamicImport.platformBrowserDynamic()
            .bootstrapModule(appModuleImport.AppModule);
        });
    })
    .catch(error => {
      console.error(error);

      alert('Task failed successfully. Reload.');
    });
}

fetchConfigAndSetWindowEnvironment();
